// React Portal
// standard.scss

* {
  box-sizing: border-box;
  position: relative;
}

html {
  font-size: 10px;
}

body {
  font-size: 1.6rem;
  color: var(--color-text);
  background-color: var(--color-canvas);
  margin: 0;
  font-family: 'Helvetica Neue', ui-sans-serif, system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

//headings
h1 {
  font-size: 2.4rem;
  line-height: 3.2rem;
  margin: 0;
}
h2 {
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin: 0;
}
h3 {
  font-size: 1.6rem;
  line-height: 2rem;
  margin: 0;
}

//body text

span,
p {
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 0;
}

label {
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin: 0;
}

//links
a {
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 0;
}

// list

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 0;
  margin: 0;
}
