@import 'src/styles/imports';

.creditPointsTag {
  width: fit-content;
  height: fit-content;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-cta);
  background-color: #e8eef4;
  border: 1px solid #c5d2df;
  border-radius: 50px;
  padding: 2px 6px;
}
