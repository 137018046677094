@import 'src/styles/imports';

.filters {
  margin: 0 auto;
  max-width: $breakpoint-m;
  width: 100%;
  padding: 0 20px;

  .filtersCard {
    padding: 20px;
    border-radius: 16px;
    // border: 1px solid var(--color-border);
    // box-shadow: 0px 1px 0px #e8e8e8;
    box-shadow: $shadow;
    margin-top: -40px;
    background-color: var(--color-card);
    display: grid;
    gap: 20px;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;

    .filter {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
