@import 'src/styles/imports';

.pdfMapUrl {
  max-width: $breakpoint-m;
  width: 100%;
  padding: 20px 20px 40px;
  margin: 0 auto;
  > h2 {
    margin-bottom: 20px;
  }
  a {
    text-decoration: none;
    color: var(--color-cta);
    display: flex;
    align-items: center;
    margin-top: 8px;

    svg {
      margin-left: 10px;
    }
  }
}
