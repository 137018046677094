@import 'src/styles/imports';

.period {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  > h3 {
    color: var(--color-text);
    font-weight: 400;
  }
  .blocks {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    row-gap: 10px;
  }
}
