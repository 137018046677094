@import 'src/styles/imports';

.notes {
  max-width: $breakpoint-m;
  width: 100%;
  margin: 0 auto;
  padding: 20px 20px 40px;

  > h2 {
    margin-bottom: 20px;
  }

  > p {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
  }
}
