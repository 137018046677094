@import 'src/styles/imports';

.year {
  > h2 {
    margin-bottom: 20px;
  }
  .periods {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
}
