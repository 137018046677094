@import 'src/styles/imports';

.courseLabel {
  height: fit-content;
  width: fit-content;
  font-size: 14px;
  font-weight: 700;
  padding: 4px 6px;
  line-height: 1.4rem;
  background-color: var(--color-card);
  border-radius: 6px;
  // visual alignment
  left: -2px;
}
