@import 'src/styles/imports';

.loadingPlan {
  height: 300px;
  width: 420px;

  @include media($max, $breakpoint-s) {
    width: 315px;
    height: 225px;
  }

  svg {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  .string {
    position: absolute;
    left: 31.7%;
    top: 12.5%;
    width: 0.6%;
    height: 25%;
    background-color: $color-primary-tinted-300;
    z-index: 1;
  }

  .crane {
    position: absolute;
    height: 79.6%;
    width: 40.3%;
    left: 0.5%;
    top: 0;
    z-index: 2;
  }

  .unit1 {
    @extend %unit;
    position: absolute;
    left: 27.5%;
    top: 30%;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      width: 18.7%;
      height: 8%;
      border-top-left-radius: 500px;
      border-top-right-radius: 500px;
      background-color: $color-blue-700;
      left: 40.6%;
      top: -8.4%;
    }
    animation: lift 6s infinite ease-in-out;
  }

  .plan {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .ladder {
    position: absolute;
    height: 29%;
    width: 16.8%;
    left: 80%;
    top: 51%;
  }

  .unit2 {
    @extend %unit;
    position: absolute;
    left: 51.5%;
    top: 49%;
    z-index: 1;
    animation: bounce 1s infinite ease-in-out;
  }

  .person1Top {
    z-index: 3;
    position: absolute;
    height: 16.8%;
    width: 6.9%;
    left: 52.7%;
    top: 63%;
    animation: bounce 1s infinite ease-in-out;
  }

  .person1Bottom {
    z-index: 2;
    position: absolute;
    height: 12.8%;
    width: 3.1%;
    left: 54.4%;
    top: 79.3%;
    animation: jump 1s infinite ease-in-out;
  }

  .shadow {
    position: absolute;
    width: 3.6%;
    height: 1%;
    background-color: $color-grey-100;
    border-radius: 100%;
    bottom: 4.9%;
    left: 54.2%;
    animation: pop 1s infinite ease-in-out;
  }

  .unit3 {
    @extend %unit;
    position: absolute;
    left: 63.5%;
    top: 30%;
    z-index: 1;
    animation: falling 2s infinite ease-in-out;

    .person2 {
      width: 191.7%;
      height: 123%;
      position: absolute;
      left: 90%;
      top: 16.5%;

      .person2Arm {
        width: 34.68%;
        height: 22.6%;
        left: 4%;
        top: -107%;
        z-index: -1;
      }
    }
  }
}

%unit {
  height: 15.2%;
  width: 9.1%;
  box-shadow: 0 4px 20px rgba(#000, 0.1);
  border-radius: 4px;
  background-color: $color-white;
  &::after {
    content: '';
    position: absolute;
    width: 62.6%;
    height: 13.2%;
    border-radius: 6px;
    background-color: $color-blue-700;
    bottom: 13.2%;
    left: 18.75%;
  }
}

@keyframes pop {
  0% {
    transform: scale(1.5);
  }

  40% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.5);
  }
}

@keyframes jump {
  0% {
    transform: translateY(20%);
  }

  40% {
    transform: translateY(-14%) scaleY(0.85);
    transform-origin: top;
  }

  100% {
    transform: translateY(20%) scaleY(1);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(20%);
  }

  40% {
    transform: translateY(-10%);
  }

  100% {
    transform: translateY(20%);
  }
}

@keyframes lift {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(-50%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes falling {
  0% {
    transform: rotate(5deg) translateX(10%) translateY(-10%);
  }

  70% {
    transform: rotate(0deg) translateX(0) translateY(0);
  }

  100% {
    transform: rotate(5deg) translateX(10%) translateY(-10%);
  }
}
