@import 'src/styles/imports';

.map,
.noMap {
  max-width: $breakpoint-m;
  width: 100%;
  padding: 40px 20px;
  margin: 0 auto;
}

.map {
  .periods {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }
}

.noMap {
  > h3 {
    margin-bottom: 10px;
  }
}

.virtuallyHidden {
  position: absolute;
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
