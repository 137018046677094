@import 'src/styles/imports';

.error {
  height: 100%;
  width: 100%;
  max-width: $breakpoint-m;
  margin: 0 auto;
  padding: 70px 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
