@import 'src/styles/imports';

.courseLabels {
  max-width: $breakpoint-m;
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;

  > ul {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
}
