@import 'src/styles/imports';

.loading,
.main {
  height: 100%;
  width: 100%;
  padding-top: 70px;
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 70px 20px 40px;
  > p {
    &:focus {
      outline: none;
    }
  }
}
