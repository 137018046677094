// React Portal

// Colour palette --------------------------------------------------------------

$color-grey-50: #fafafa;
$color-grey-100: #f5f5f5;
$color-grey-200: #ebebeb;
$color-grey-300: #d0d0d0;
$color-grey-400: #a3a3a3;
$color-grey-500: #747474;
$color-grey-600: #525252;
$color-grey-700: #404040;
$color-grey-800: #333333;
$color-grey-900: #171717;

$color-slate-50: #f8fafc;
$color-slate-100: #e8eef4;
$color-slate-200: #e2e8f0;
$color-slate-300: #cbd5e1;
$color-slate-400: #a1b6ca;
$color-slate-500: #718199;
$color-slate-600: #566780;
$color-slate-700: #3d4e66;
$color-slate-800: #293851;
$color-slate-900: #172340;

$color-blue-50: #eff6ff;
$color-blue-100: #dbeafe;
$color-blue-200: #bfdbfe;
$color-blue-300: #91bded;
$color-blue-400: #5893da;
$color-blue-500: #2c6cba;
$color-blue-600: #2760a5;
$color-blue-700: #204f88;
$color-blue-800: #183c67;
$color-blue-900: #133053;

$color-teal-50: #f0fdfa;
$color-teal-100: #ccfbf1;
$color-teal-200: #99f6e4;
$color-teal-300: #5eead4;
$color-teal-400: #2dd4bf;
$color-teal-500: #14b8a6;
$color-teal-600: #0d8294;
$color-teal-700: #2d6c7a;
$color-teal-800: #11505e;
$color-teal-900: #13434e;

$color-green-50: #f0fdf4;
$color-green-100: #dcfce7;
$color-green-200: #bbf7d0;
$color-green-300: #99e5a0;
$color-green-400: #79d281;
$color-green-500: #54b659;
$color-green-600: #429a46;
$color-green-700: #38853c;
$color-green-800: #215e29;
$color-green-900: #14531c;

$color-yellow-50: #fefce8;
$color-yellow-100: #fef9c3;
$color-yellow-200: #fef08a;
$color-yellow-300: #fde047;
$color-yellow-400: #facc15;
$color-yellow-500: #eab308;
$color-yellow-600: #ca8a04;
$color-yellow-700: #a7642a;
$color-yellow-800: #854d0e;
$color-yellow-900: #713f12;

$color-orange-50: #fff7ed;
$color-orange-100: #ffedd5;
$color-orange-200: #fed7aa;
$color-orange-300: #fdba74;
$color-orange-400: #ff9800;
$color-orange-500: #f97316;
$color-orange-600: #ea580c;
$color-orange-700: #c2410c;
$color-orange-800: #9a3412;
$color-orange-900: #7c2d12;

$color-red-50: #fef2f2;
$color-red-100: #fee2e2;
$color-red-200: #fecaca;
$color-red-300: #fca5a5;
$color-red-400: #f87171;
$color-red-500: #f44336;
$color-red-600: #d0372b;
$color-red-700: #b91c1c;
$color-red-800: #991b1b;
$color-red-900: #7f1d1d;

$color-purple-50: #faf5ff;
$color-purple-100: #f3e8ff;
$color-purple-200: #e9d5ff;
$color-purple-300: #cfbce6;
$color-purple-400: #ba9edb;
$color-purple-500: #a786d0;
$color-purple-600: #9333ea;
$color-purple-700: #764ea6;
$color-purple-800: #604088;
$color-purple-900: #492970;

$color-white: #ffffff;
$color-black: #000000;

// Semantic colours ------------------------------------------------------------

// Primary
$color-primary-cta: $color-blue-700;
$color-primary-cta-hover: $color-blue-900;
$color-primary-focus: $color-blue-500;
$color-primary-tinted-100: $color-slate-100;
$color-primary-tinted-200: $color-slate-300;
$color-primary-tinted-300: $color-slate-400;

// Status
$color-status-success: $color-green-700;
$color-status-warning: $color-orange-400;
$color-status-attention: $color-red-600;

// Labels
$color-label-blue: #212d96;
$color-label-prussian: #003249;
$color-label-green: #2d6c7a;
$color-label-orange: #a7642a;
$color-label-brown: #8f3900;
$color-label-yellow: #737439;
$color-label-army: #504a20;
$color-label-pink: #86456c;
$color-label-purple: #604088;
$color-label-ruby: #7b0e29;
$color-label-sienna: #2e0f15;
$color-label-taupe: #776768;

// default theme
:root {
  --color-cta: #{$color-blue-700};
  --color-cta-active: #{$color-blue-900};
  --color-cta-alt: #{$color-white};
  --color-cta-alt-active: #{$color-slate-100};
  --color-canvas: #{$color-grey-50};
  --color-canvas-alt: #{$color-blue-700};
  --color-card: #{$color-white};
  --color-card-active: #{$color-slate-100};
  --color-card-alt: #{$color-blue-800};
  --color-card-alt-active: #{$color-blue-900};
  --color-card-2nd-alt: #{$color-blue-900};
  --color-card-disabled: #{$color-grey-200};
  --color-text: #{$color-grey-800};
  --color-text-alt: #{$color-white};
  --color-text-2nd: #{$color-grey-500};
  --color-text-2nd-alt: #{$color-slate-100};
  --color-border: #{$color-grey-200};
  --color-border-active: #{$color-blue-700};
  --color-border-alt: #{$color-grey-800};
  --color-border-alt-active: #{$color-blue-400};
  --color-border-2nd: #{$color-grey-100};
  --color-border-2nd-alt: #{$color-blue-800};
  --color-outline: #{$color-blue-400};
  --color-outline-alt: #{$color-blue-400};
  --color-disabled: #{$color-grey-400};
  --color-success: #{$color-green-700};
  --color-warning: #{$color-orange-400};
  --color-attention: #{$color-red-600};
  --color-attention-active: #{$color-red-800};
}
