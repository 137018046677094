@import 'src/styles/imports';

.gridBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  background-color: var(--color-card);
  border: 1px solid var(--color-border-2nd);
  // box-shadow: 0px 1px 0px #e8e8e8;
  box-shadow: $shadow;
  gap: 8px;
  padding: 16px;
  min-height: 140px;
  opacity: 0;
  transform: translateY(-10px);
  animation: DropIn 1s ease-in-out forwards;

  .unitCode {
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 0;
    font-weight: 700;
    > abbr {
      text-decoration: none;
      pointer-events: none;
    }
  }

  .title {
    height: 100%;
    flex-grow: 1;
    line-height: 1.25;
    padding-bottom: 8px;
    hyphens: auto;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }

  &.elective {
    border: 1px solid var(--color-border);
    background-color: $color-grey-200;
    box-shadow: inset 1px 1px 0px #e6e6e6;

    .title {
      padding-bottom: 0;
    }
  }
}

.virtuallyHidden {
  position: absolute;
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
