@import 'src/styles/imports';

.dropdown {
  display: inline-block;
  position: relative;
  width: 100%;
  button {
    appearance: none;
    padding: none;
    background: none;
    border: none;
  }
  .dropdownSelect {
    background-color: var(--color-card);
    border: 1px solid var(--color-border);
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1em;
    min-height: calc(1.4em + 20px);
    padding: 10px;
    text-align: left;
    cursor: pointer;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    hyphens: auto;
    .arrow {
      transition: transform 0.3s ease;
      &.up {
        transform: rotate(180deg);
      }
    }

    &:focus-visible {
      outline: 2px solid var(--color-cta);
    }
  }
  .filled {
    background-color: var(--color-card-active);
  }
  .selectList {
    width: 100%;
    background-color: var(--color-card);
    border: 1px solid var(--color-border);
    border-radius: 8px;
    overflow-y: auto;
    z-index: 2;
    display: block;
    max-height: 200px;

    li {
      list-style: none;
    }
  }
  .category {
    width: 100%;
    padding: 4px 0px;
    border-bottom: 1px solid var(--color-border);
    .categoryLabel {
      font-size: 1.4rem;
      width: 100%;
      padding: 4px 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: var(--color-text-2nd);
    }
  }
}
.dropdownItem {
  width: 100%;
  display: flex;
  flex-direction: row;

  align-items: center;
  cursor: pointer;
  padding: 4px 10px;
  .itemText {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      /* These are technically the same, but use both */
      overflow-wrap: break-word;
      word-wrap: break-word;

      word-break: break-word;

      /* Adds a hyphen where the word breaks, if supported (No Blink) */
      hyphens: auto;
      text-align: left;
    }
    .label {
      color: var(--color-text-2nd);
    }
  }

  &:focus-within {
    outline: 2px solid var(--color-cta);
    border-radius: 8px;
    outline-offset: -2px;
  }
  &:hover {
    background-color: var(--color-card-active);
  }
}
.isSelected {
  outline: 2px solid var(--color-cta);
  border-radius: 8px;
  outline-offset: -2px;
}
