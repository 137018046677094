// breakpoints

$breakpoint-s: 800px;
$breakpoint-m: 1200px;

// shadow

$shadow: 0 1px 2px rgba(#000, 0.03), 0 2px 4px rgba(#000, 0.03),
  0 4px 8px rgba(#000, 0.03), 0 8px 16px rgba(#000, 0.03),
  0 16px 32px rgba(#000, 0.03);
