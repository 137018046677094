@import '../../styles/imports';

.topNav {
  position: fixed;
  z-index: 10;
  height: 70px;
  width: 100%;
  display: flex;
  padding: 0px 20px;
  color: var(--color-text);
  background-color: var(--color-canvas);
  align-items: center;
  border-bottom: 1px solid var(--color-border);

  .appName {
    min-width: 260px;
    padding: 8px 0px;
    display: flex;
    align-items: center;
    gap: 10px;

    .divider {
      height: 30px;
      border: 1px solid var(--color-border);
    }

    .title {
      margin: 0;
      font-size: 16px;
      font-weight: 700;
    }
  }
}
