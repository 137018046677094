@import 'src/styles/imports';

.banner {
  height: 25vh;
  min-height: 300px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-alt);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  padding: 0 20px;

  > div {
    max-width: $breakpoint-m;
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  img {
    z-index: -1;
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center center;
  }
}
