// media

$max: max-width;
$min: min-width;

@mixin media($type, $width) {
  @media screen and ($type: $width) {
    @content;
  }
}
